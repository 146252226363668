import React, { useContext, useEffect, useState } from "react";
import Logo from "../asset/Image/logo.png";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import LoadingBtn from "../component/LoadingBtn";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import Guide from "../asset/videos/Guide.webm";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
function Login() {
  const { submitLogin, loading, setLoading, loginform, Setloginform, logOut } =
    useContext(AuthContext);
  const [tab, setTab] = useState("signin");
  const navigate = useNavigate();
  const [forgotPopup, setforgotPopup] = useState(false);
  const [guide, setGuide] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [departments, setDepartments] = useState([]);
  const [signUp, setSignup] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    branch: "",
    department: 0,
    password: "",
  });
  const getCsrfToken = () => {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("csrftoken="))
      ?.split("=")[1];
    return cookieValue || "";
  };

  useEffect(() => {
    logOut();
  }, []);

  const handleLoginchange = (e) => {
    Setloginform((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const forgotPassword = async (e) => {
    e.preventDefault();
    setResetLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_URL}/api/password_reset/`,
        { email },
        {
          headers: {
            Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
          },
        }
      )
      .then((res) => {
        setResetLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        setResetLoading(false);
        console.log(err);
      });
  };

  const signupForm = (e) => {
    setSignup((prev) => {
      return {
        ...prev,
        [e.target.name]:
          e.target.name === "department"
            ? Number(e.target.value)
            : e.target.value,
      };
    });
  };

  const handleSignup = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (signUp.email.split("@")[1].toLowerCase().includes("awashwine.com")) {
      await axios
        .post(
          `${process.env.REACT_APP_URL}/api/create_user/`,
          { ...signUp, username: signUp.email },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": getCsrfToken(),
              Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
            },
          }
        )
        .then((res) => {
          setSignup({
            username: "",
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            branch: "",
            department: 0,
            password: "",
          });
          setTab("signin");
          setLoading(false);
          toast.success(
            "You are Successfuly Registered Please Confirm in Your Email"
          );
        })
        .catch((err) => {
          setLoading(false);
          toast.error(
            "Something went wrong please try again later !! if error persist contact your adminstrator "
          );

          console.log(err);
        });
    } else {
      toast.error("Wrong Email Address");
      setLoading(false);
    }
  };

  const fetchData = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/api/departments/`, {
        headers: {
          Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deptOpt = departments?.map((el) => (
    <option value={el.id} key={el.id}>
      {el.name}
    </option>
  ));
  return (
    <div className="login-page">
      <div className={`video-guide ${guide ? "active" : ""}`}>
        <div className="close-guide" onClick={() => setGuide(false)}>
          <CloseRoundedIcon sx={{ fontSize: 32 }} />
        </div>
        <video controls={true}>
          <source src={Guide} width="100%" height="100%" type="video/webm" />
          {/* <source src={Guide} width="100%" height="100%" type="video/webm" /> */}
        </video>
      </div>
      <div className="guide" onClick={() => setGuide(true)}>
        <VideocamRoundedIcon sx={{ fontSize: 40 }} />
      </div>
      <ToastContainer />
      {/* popup*/}
      <div className={`forgot-password_popup ${forgotPopup && "active"}`}>
        <div
          className="popup-pin"
          onClick={() => {
            setforgotPopup(false);
          }}
        ></div>

        <div className="popup_header">
          <h2>Reset your Password</h2>
        </div>

        <form
          className="reset-form"
          onSubmit={forgotPassword}
          onChange={(e) => setEmail(e.target.value)}
        >
          <div className="form-ctrl">
            <label htmlFor="reset-email">
              Email Address <span>*</span>
            </label>
            <input type="email" name="email" id="reset-email" />
          </div>
          <div className="forgot-signin sigup-btn-conatiner">
            {resetLoading ? (
              <LoadingBtn />
            ) : (
              <button className="btn-login">Send Reset Link</button>
            )}
          </div>
        </form>
      </div>
      {/* popup*/}
      <div className="login-left">
        <div className="tab-container">
          <div className="header-container">
            <div className="logo">
              <img src={Logo} alt="Logo" width="45px" />
            </div>
            <div className="app-name">
              <h2>Awash Wine IT Help Desk</h2>
            </div>
          </div>
          {/* signin container */}
          <div
            className={`login-container ${tab === "signin" && "active-signin"}`}
          >
            <form
              className="login-form"
              onSubmit={submitLogin}
              onChange={handleLoginchange}
            >
              <div className="login-header">
                <h1>Welcome Back !</h1>
              </div>
              <div className="form-ctrl">
                <label htmlFor="emaillog">Email Address</label>
                <input type="text" name="email" id="emaillog" />
              </div>
              <div className="form-ctrl">
                <label htmlFor="password">Password</label>
                <input type="password" name="password" id="password" />
              </div>
              <div className="forgot-signin">
                <p
                  onClick={() => {
                    setforgotPopup(true);
                  }}
                >
                  Forgot Password ?
                </p>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <button className="btn-login">Sign In</button>
                )}
              </div>
            </form>
            <div className="signup">
              <p>Don't you have an account?</p>{" "}
              <span
                className="tab"
                onClick={() => {
                  setTab("signup");
                }}
              >
                {" "}
                Sign Up
              </span>
            </div>
          </div>
          {/* signin container */}

          {/* signup container */}
          <div
            className={`signup-container ${
              tab === "signup" && "active-signup"
            }`}
          >
            <form className="login-form" onSubmit={handleSignup}>
              <div className="login-header">
                <h1 className="create-account">Create you account</h1>
              </div>
              <div className="form-ctrl">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  onChange={signupForm}
                  value={signUp.first_name}
                />
              </div>
              <div className="form-ctrl">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  onChange={signupForm}
                  value={signUp.last_name}
                />
              </div>
              <div className="form-ctrl">
                <label htmlFor="email">Email Address</label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  onChange={signupForm}
                  value={signUp.email}
                />
              </div>
              <div className="form-ctrl">
                <label htmlFor="phone_number">Phone Number</label>
                <input
                  type="number"
                  name="phone_number"
                  id="phone_number"
                  onChange={signupForm}
                  value={signUp.phone_number}
                />
              </div>
              <div className="form-ctrl">
                <label htmlFor="brach">Branch</label>
                <select
                  name="branch"
                  id="branch"
                  onChange={signupForm}
                  value={signUp.branch}
                >
                  <option value="">Select Your Branch</option>
                  <option value="Farm">Farm</option>
                  <option value="Lideta">Lideta</option>
                  <option value="Mekanissa">Mekanissa</option>
                </select>
              </div>
              <div className="form-ctrl">
                <label htmlFor="department">Department</label>
                <select
                  name="department"
                  id="department"
                  onChange={signupForm}
                  value={signUp.department}
                >
                  <option value="">Select Your Department</option>
                  {deptOpt}
                </select>
              </div>
              <div className="form-ctrl">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="spassword"
                  onChange={signupForm}
                  value={signUp.password}
                />
              </div>
              <div className="forgot-signin sigup-btn-conatiner">
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <button className="btn-login">Sign Up</button>
                )}
              </div>
            </form>
            <div className="signup">
              <p>Have an account?</p>
              <span
                className="tab"
                onClick={() => {
                  setTab("signin");
                }}
              >
                {" "}
                Sign In
              </span>
            </div>
          </div>
          {/* signup container */}
        </div>
      </div>
      <div className="login-right"></div>
    </div>
  );
}

export default Login;
