import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { AuthContext } from '../Context/AuthContext'
import Cookies from 'js-cookie'
import Loading from './Loading'
function ProtectedRoutes() {

  const {authToken,user,updateToken,authLoad}=useContext(AuthContext)
  const [isLoading,setisLoading]=useState(false)
  const navigate=useNavigate()
    
  const getCsrfToken = () => {
    const cookieValue = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    return cookieValue || '';
  };


 
  


  useEffect(()=>{
  
        if(authLoad){
          updateToken()
        }
          let fourMinutes=1000*60*4
    
    
          let interval=setInterval(()=>{
            if(authToken){
              updateToken()
              console.log("reloaded");
              
            }
          },fourMinutes)
    
          return ()=>clearInterval(interval)
        },[authToken,authLoad])

  if(isLoading){
    return <Loading/>
}else{

  // return isAuth && isAuth? <Outlet/> : <Navigate to="/"/>
  return authToken?.user?.role==="employee"?<Outlet/>:<Navigate to="/"/>
}
}

export default ProtectedRoutes
