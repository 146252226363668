import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import Cookies from 'js-cookie';
import Loading from './Loading';

function AdminRoutes() {
  const { authToken,updateToken,authLoad } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getCsrfToken = () => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    return cookieValue || '';
  };

  
  useEffect(()=>{

      if(authLoad){
        updateToken()
      }
        let fourMinutes=1000*60*4
  
  
        let interval=setInterval(()=>{
          if(authToken){
            updateToken()
            
          }
        },fourMinutes)
  
        return ()=>clearInterval(interval)
      },[authToken,authLoad])

  if (isLoading) {
    return <Loading/>;
  } else {
    return authToken?.user?.role==="admin"?<Outlet /> :<Navigate to="/" />;
  }
}

export default AdminRoutes;
