import axios from 'axios'
import React, { useEffect,useState } from 'react'
import { Link, useParams } from 'react-router-dom';
function Activate() {

      const [success,setSuccess]=useState(null)
      const {token}=useParams()
    

    const getCsrfToken = () => {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1];
        return cookieValue || '';
    };


    console.log(token);
    
    const activateAccount=async ()=>{
        await axios.post(`${process.env.REACT_APP_URL}/api/activate_account/${token}/`,null,{ withCredentials: true ,headers: {
      'X-CSRFToken': getCsrfToken(),
      Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
  }}).then(res=>{
    setSuccess(true)
    console.log(res.data);
    
  }).catch(err=>{
    console.log(err)
  })
    }

    useEffect(()=>{
        activateAccount()
    },[])
  return (
    <div>
      {
        !success?<div className='message'>
            Account not Activated Please Contact System Adminstrator !! 
        </div>:<div className='message'>
            Account Activated !!
            <Link to="/">Back to login</Link>
        </div>
    }
    </div>
  )
}

export default Activate
