import { json, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './Pages/Login';
import Logo from "./asset/Image/logo.png";
import Header from './component/Header';
import Dashboard from './Pages/user/Dashboard';
import { useEffect, useState } from 'react';
import AdminHeader from "./component/AdminHeader"
import AdminDashboard from './Pages/admin/AdminDashboard';
import Completed from './Pages/admin/Completed';
import Users from './Pages/admin/Users';
import { Outlet,useNavigate,Link } from 'react-router-dom';
import Manage from './Pages/admin/Manage';
import Inprogress from './Pages/admin/Inprogress';
import ProtectedRoutes from './Pages/ProtectedRoutes';
import { AuthContext } from './Context/AuthContext';
import Tickets from './Pages/admin/Tickets';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import GroupIcon from '@mui/icons-material/Group';
import ForumIcon from '@mui/icons-material/Forum';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AdminRoutes from './Pages/AdminRoutes';
import ResetPassword from './Pages/ResetPassword';
import Loading from './Pages/Loading';
import Report from './Pages/admin/Report';
import Department from './Pages/admin/Department';
import Activate from './Pages/Activate';
import { toast, ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
// import {useHistory} from "react-router-dom"
function App() {
  const [loading,setLoading]=useState(false)
  const [authLoad,setAuthLoad]=useState(true)
  const [isAdmin, setIsAdmin] = useState(sessionStorage.getItem("isAdmin") || false)
  const [authToken,setAuthToken]=useState(()=>localStorage.getItem("authTokens")?JSON.parse(localStorage.getItem("authTokens")):null)
  const [user,setUser]=useState(()=>localStorage.getItem("authTokens")?jwtDecode(localStorage.getItem("authTokens"))?.email?.split("@")[0]:null)
  const [page, setPage] = useState(sessionStorage.getItem("page") || "Dashboard")
  const navigate=useNavigate()
  const [loginform, Setloginform] = useState({ email: "", password: "" })
  const getCsrfToken = () => {
      const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1];
      return cookieValue || '';
  };

  useEffect(() => {
    sessionStorage.setItem("page", page)
  }, [page])


  const url = window.location.pathname
  const urlsplit = url.split("/")
  useEffect(() => {
    if (urlsplit[urlsplit.length - 1] === "admin") {
      return setPage("dashboard")
    }
    if (urlsplit[urlsplit.length - 1] === "inprogress") {
      return setPage("In Progress")
    }
    setPage(urlsplit[urlsplit.length - 1])

  }, [])


  const ToggleSideBar = () => {
    const sidebar = document.querySelector(".sidebar")
    const sidebarhum = document.querySelectorAll(".sidebar-humburger div")
    sidebar.classList.toggle("active")
    sidebarhum.forEach(e => {
      e.classList.toggle('active')
    });
  }

  const AdminLayout = () => {
    return <div className="dashboard-layout">
      <div className="sidebar">
        <div className="sidebar-humburger" onClick={ToggleSideBar}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="logo"><img src={Logo} alt="logo" width="45px" /></div>
        <div className="sidebar-main">
          <ul>
            <Link to="/admin/dashboard" onClick={() => setPage("dashboard")} className={page === "dashboard" ? "active":""}><DashboardIcon sx={{fontSize:"25px"}}/> Dashboard</Link>
            <Link to="tickets" onClick={() => setPage("tickets")} className={page === "tickets" ? "active":""}><ConfirmationNumberIcon sx={{fontSize:"25px"}}/> Tickets</Link>
            <Link to="users" onClick={() => setPage("users")} className={page === "users" ? "active":""}><GroupIcon sx={{fontSize:"25px"}}/>Users</Link>
            <Link to="department" onClick={() => setPage("department")} className={page === "department" ? "active":""}><AssessmentIcon sx={{fontSize:"25px"}}/>Department</Link>
            <Link to="reports" onClick={() => setPage("reports")} className={page === "reports" ? "active":""}><AssessmentIcon sx={{fontSize:"25px"}}/>Report</Link>
          </ul>
        </div>
      </div>
      <div className="admindashboard-main">
        <AdminHeader
          togglesidebar={ToggleSideBar}
        />
        <div className="admin_main-container">
          <div className="page-name"><h1>{page}</h1></div>
          <div className="admin-outlets">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  }

  const submitLogin = (e) => {
      e.preventDefault()
  
      setLoading(true)
  
      axios.post(`${process.env.REACT_APP_URL}/api/token/`,loginform,{ withCredentials: true ,headers: {
        'X-CSRFToken': getCsrfToken(),
        Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`,
    }})
      .then(async res => {
     
        

        if(res.status===200){
          
          await axios.get(`${process.env.REACT_APP_URL}/api/check_activation_status/${res.data?.user?.id}`,{
            headers:{
              'X-CSRFToken': getCsrfToken(),
        Authorization: `API_KEY ${process.env.REACT_APP_API_KEY}`
            }
          }).then(resp=>{
            // console.log(resp.data);
            
            if(resp.data.activated){
              console.log("true");
          setAuthToken(res.data)
          setUser(jwtDecode(res.data.access).email.split("@")[0])
          localStorage.setItem('authTokens', JSON.stringify(res.data));
          setLoading(false)

          if(res.data.user.role==="admin"){
            navigate("/admin/dashboard")
          }else if(res.data.user.role==="employee"){
            navigate("/dashboard")
          }
            }else{
              toast.error("Please Activate Your Account In You Email")
              setLoading(false)
            }
          }).catch(err=>{
            setLoading(false)
            toast.error("Something Went Wrong, Please Try Again Later!!")
            return
          })
          
          
        }
        
      }).catch(err => {
        setLoading(false)
        if(typeof(err?.response?.data)==="object"){
          
          let errors=Object.keys(err?.response?.data)
          errors.forEach(error => {
          if(error!=="non_field_errors"){
          return  toast.warning(`${error}: ${err?.response?.data[error][0]}`)
          }
          return toast.warning(`${err?.response?.data[error][0]}`)
        });
        }else{
          toast.warning(`Server Error Please Try Again Later`)
        }
        
        
        return 
      })
    }

    const logOut=()=>{

      
      localStorage.removeItem("authTokens")
      localStorage.removeItem("user")
      setAuthToken(null)
      setUser(null)
      navigate("/")
    }

    let updateToken=async ()=>{

      await axios.post(`${process.env.REACT_APP_URL}/api/token/refresh/`,{refresh:authToken?.refresh},
                {
                  headers: {
                    'X-CSRFToken': getCsrfToken(),
                  },
                }
              ).then(res=>{
                
                if(res.status===200){
                  setAuthToken(prev=>{
                return {
                ...prev,
                access:res.data.access}})

                  setUser(jwtDecode(res.data.access).email.split("@")[0])
                  localStorage.setItem('authTokens', JSON.stringify({...authToken,access:res.data.access}));
                }else{
                  console.log("logged out");
                  
                  logOut()

                }

                if(authLoad){
                  setAuthLoad(false)                  
                }

                return 
              }).catch(err=>{
                toast.error("Session Expired")
                logOut()
                return 
              })
    }

    
    

  return (

    <div className="App">
      <AuthContext.Provider value={{authToken,logOut, setIsAdmin,user,setUser,submitLogin,loading,setLoading,loginform,updateToken, Setloginform,authLoad }}>
        {/* <Header/> */}
        <Routes>
          <Route index element={<Login />}></Route>
          <Route path="/activate/:token" element={<Activate/>}/>
          <Route path='/reset/:param1/:param2' element={<ResetPassword />}></Route>
          <Route element={<ProtectedRoutes/>}>
            <Route path="/dashboard" element={<Dashboard />} />
            
          </Route>
          <Route element={<AdminRoutes/>}>

          <Route  element={<AdminLayout />}>
              <Route path="/admin/dashboard" element={<AdminDashboard />} />
              <Route path='tickets' element={<Tickets />} />
              <Route path='users' element={<Users />} />
              <Route path='manage' element={<Manage />} />
              <Route path='department' element={<Department />} />
              <Route path='reports' element={<Report />} />
            </Route>
          </Route>

            <Route path="*" element={<h1>404 Page not Found</h1>}></Route>
            <Route path="/loading" element={<Loading/>}></Route>
        </Routes>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
